import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons"

interface IPagination {
  itemsCount: number
  pageSize: number
  currentPage: number
  onPageChange: (page: number) => void
}
export default function Pagination(props: IPagination) {
  const { itemsCount, pageSize, currentPage, onPageChange } = props
  const pagesCount = Math.ceil(itemsCount / pageSize)
  const firstPage = Math.max(currentPage - 4, 1)
  const lastPage = Math.min(
    currentPage - 4 <= 0 ? 4 - currentPage + currentPage + 5 : currentPage + 4,
    pagesCount
  )
  const pages = []
  for (let i = firstPage; i <= lastPage; i++) {
    pages.push(i)
  }

  const showEllipsis = pagesCount - currentPage > 9
  console.log(
    "ps",
    pageSize,
    "pages count",
    pagesCount,
    "fp",
    firstPage,
    "lp",
    lastPage
  )

  return (
    <nav className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0">
      <div className="-mt-px flex w-0 flex-1">
        <button
          className={`inline-flex items-center border-t-2 border-transparent pt-4 pr-1 text-sm font-medium ${
            currentPage === 1
              ? "text-gray-200"
              : "text-gray-500 hover:border-gray-300 hover:text-gray-700"
          }`}
          disabled={currentPage === 1}
          onClick={() => onPageChange(currentPage - 1)}
        >
          <FontAwesomeIcon
            icon={faArrowLeft}
            className={`mr-3 h-5 w-5 ${
              currentPage === 1 ? "text-gray-200" : "text-gray-400"
            }`}
            aria-hidden="true"
          />
          Previous
        </button>
      </div>
      <div className="hidden md:-mt-px md:flex">
        {pages.map((page) => (
          <button
            key={page}
            className={
              page === currentPage
                ? "inline-flex items-center border-t-2 border-cyan-500 px-4 pt-4 text-sm font-medium text-cyan-600"
                : "inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
            }
            onClick={() => onPageChange(page)}
          >
            {page}
          </button>
        ))}

        {showEllipsis && (
          <button
            className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500"
            onClick={() => onPageChange(currentPage + 10)}
          >
            ...
          </button>
        )}
      </div>
      <div className="-mt-px flex w-0 flex-1 justify-end">
        <button
          className={`inline-flex items-center border-t-2 border-transparent pt-4 pr-1 text-sm font-medium ${
            currentPage === pagesCount
              ? "text-gray-200"
              : "text-gray-500 hover:border-gray-300 hover:text-gray-700"
          }`}
          disabled={currentPage === pagesCount}
          onClick={() => onPageChange(currentPage + 1)}
        >
          Next
          <FontAwesomeIcon
            icon={faArrowRight}
            className={`mr-3 h-5 w-5 ${
              currentPage === pagesCount ? "text-gray-200" : "text-gray-400"
            }`}
            aria-hidden="true"
          />
        </button>
      </div>
    </nav>
  )
}
