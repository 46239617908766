import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch } from "@fortawesome/free-solid-svg-icons"
import { Dispatch, SetStateAction, useState } from "react"
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid"
import { Combobox } from "@headlessui/react"
import { SermonData } from "../../global/dataTypes"

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ")
}

interface ISearchSermonsBar {
  sermons: SermonData[]
  selectedSermon: SermonData | null
  setSelectedSermon: Dispatch<SetStateAction<SermonData | null>>
}

export default function SearchSermonsBar(props: ISearchSermonsBar) {
  const { sermons, selectedSermon, setSelectedSermon } = props
  const [query, setQuery] = useState("")

  const filteredSermons =
    query === ""
      ? sermons
      : sermons.filter((sermon) => {
          return sermon.title.toLowerCase().includes(query.toLowerCase())
        })

  return (
    <Combobox
      as="div"
      value={selectedSermon}
      onChange={setSelectedSermon}
      nullable
    >
      <div className="relative mt-2">
        <div className="relative flex items-center">
          <div className="absolute inset-y-0 left-0 flex py-1.5 pl-1.5">
            <kbd className="inline-flex items-center rounded border border-gray-200 px-1 font-sans text-xs text-gray-400">
              <FontAwesomeIcon icon={faSearch} />
            </kbd>
          </div>
          <Combobox.Input
            placeholder="Search sermons by title"
            className="w-full h-11 rounded-md border-0 bg-white py-1.5 pr-10 pl-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-cyan-600 sm:text-sm sm:leading-6"
            onChange={(event) => setQuery(event.target.value)}
            displayValue={(sermon: SermonData) => sermon?.title ?? ""}
          />
        </div>
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Combobox.Button>

        {filteredSermons.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-gray-200 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredSermons.map((sermon) => (
              <Combobox.Option
                key={sermon.embedMedium}
                value={sermon}
                className={({ active }) =>
                  classNames(
                    "relative cursor-default select-none py-2 pl-3 pr-9",
                    active ? "bg-cyan-600 text-white" : "text-gray-900"
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <span
                      className={classNames(
                        "block truncate",
                        selected ? "font-semibold" : ""
                      )}
                    >
                      {sermon.title}
                    </span>

                    {selected && (
                      <span
                        className={classNames(
                          "absolute inset-y-0 right-0 flex items-center pr-4",
                          active ? "text-white" : "text-cyan-600"
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  )
}
