import Navbar from "../components/Navbar"
import SideScrollComponent from "../components/home/SidescrollComponent"
import { Link } from "react-router-dom"
import fromMoodyStage from "../images/HHHMoodyChapel.jpg"
import worship from "../images/worship.jpg"

export default function Home() {
  return (
    <div className="bg-white">
      <Navbar />
      <main>
        <div className="lg:relative">
          <div className="mx-auto max-w-7xl pt-8 sm:pt-24 sm:px-6 lg:px-8">
            <h1 className="text-4xl font-bold tracking-tight text-center text-gray-00 sm:text-5xl md:text-6xl lg:text-left lg:w-1/2">
              <span className="block md:inline">Corkin' Good</span>{" "}
              <Link to={"/sermons"} className="block text-cyan-600 md:inline">
                Sermons
              </Link>
            </h1>
          </div>
          <div className="relative h-64 w-full sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:h-full lg:w-1/2">
            <img
              className="absolute pt-8 h-full w-full object-cover lg:pl-1 lg:pb-1 lg:pt-0"
              src={fromMoodyStage}
              alt=""
            />
            <svg
              className="relative h-full w-1/6 hidden text-white lg:block"
              fill="currentColor"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
              aria-hidden="true"
            >
              <polygon points="0, 0 100, 0 50, 100 0, 100" />
            </svg>
          </div>
          <div className="mx-auto w-full max-w-7xl px-4 pt-8 pb-8 sm:px-6 lg:px-8">
            <div className="prose prose-lg text-lg text-gray-500 sm:text-xl md:mt-5 lg:w-1/2">
              <p>
                I know what you’re thinking: “This is a pretty arrogant name for
                a sermon website!”{" "}
                <span className="block inline text-gray-500 font-bold">
                  Hear me out.
                </span>{" "}
                The genesis of the name is this: My preaching mentor, Dr. Haddon
                Robinson, once said to me,
              </p>
              <blockquote>
                <p className="text-xl text-gray-500">
                  Too many preachers preach a mediocre sermon and then try to
                  rescue it at the end with a corkin' good story.
                </p>
              </blockquote>
              <p>
                I have heard it done and I have done it. So, in order to attract
                some attention to the site and not take myself too seriously I
                chose: "
                <span className="block inline text-gray-500 font-bold">
                  Corkin' Good Sermons.
                </span>
                " In my defense, I left the exclamation point off the end.
              </p>
            </div>
          </div>
        </div>
        <div className="relative px-2 sm:px-4 sm:pt-12 pb-16 sm:pb-24">
          <div className="lg:mx-auto lg:grid lg:grid-cols-2 lg:items-start lg:gap-24 lg:max-w-max">
            <div className="relative sm:py-16 lg:py-0 lg:max-w-xl">
              <div
                aria-hidden="true"
                className="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen"
              >
                <div className="absolute inset-y-0 right-1/2 w-full rounded-r-3xl bg-gray-100 lg:right-72" />
                <svg
                  className="absolute top-8 left-1/4 -ml-3 lg:-right-8 lg:left-auto lg:top-12"
                  width={404}
                  height={392}
                  fill="none"
                  viewBox="0 0 404 392"
                >
                  <defs>
                    <pattern
                      id="02f20b47-fd69-4224-a62a-4c9de5c763f7"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        x={0}
                        y={0}
                        width={4}
                        height={4}
                        className="text-gray-300"
                        fill="currentColor"
                      />
                    </pattern>
                  </defs>
                  <rect
                    width={404}
                    height={392}
                    fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)"
                  />
                </svg>
              </div>
              <div className="relative pt-12 mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-none lg:px-0 lg:py-20">
                <div className="relative overflow-hidden rounded-2xl pt-16 pb-10 shadow-xl">
                  <img
                    className="absolute inset-0 h-full w-full object-cover"
                    src={worship}
                    alt="worship"
                  />
                  <div className="absolute inset-0  mix-blend-multiply" />
                  <div className="absolute inset-0 bg-gradient-to-t from-cyan-600 opacity-90" />
                  <div className="relative px-8">
                    <SideScrollComponent />
                    <blockquote className="mt-8">
                      <div className="relative text-lg font-medium text-white md:flex-grow">
                        <svg
                          className="absolute top-0 left-0 h-8 w-8 -translate-x-3 -translate-y-2 transform text-cyan-400"
                          fill="currentColor"
                          viewBox="0 0 32 32"
                          aria-hidden="true"
                        >
                          <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                        </svg>
                        <p className="relative">
                          The purpose of preaching is not to make the preacher
                          look good or the listener feel good. The purpose is
                          for all of us to be more like Jesus because we have
                          heard and submitted to the Word.
                        </p>
                      </div>

                      <footer className="mt-4">
                        <p className="text-base font-semibold text-cyan-200">
                          - Dave Gibson
                        </p>
                      </footer>
                    </blockquote>
                  </div>
                </div>
              </div>
            </div>

            <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">
              <div className="mx-auto max-w-prose text-base lg:max-w-none">
                <p className="mt-12 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
                  Transformation is the Goal, full stop!
                </p>
              </div>
              <div className="my-10 mx-auto max-w-prose text-base lg:mx-0">
                <p className="text-lg text-gray-500">
                  After forty years of preaching, I have a very intentional and
                  deeply held philosophy about sermons, preaching, and sermon
                  goals. I will spare you the ninety-page version but these are
                  the key points of my communication philosophy:
                </p>
              </div>
              <div className="prose prose-cyan mx-auto text-gray-500 ">
                <span className="block text-gray-500 font-bold italic">
                  First, the expected outcome of giving and hearing any sermon
                  is personal transformation.
                </span>
                <p className="lg:ml-3 lg:mt-0">
                  We must never mock God by hearing His Word and walking away
                  unchanged—going about life without reflecting. We must be
                  doers of the Word. We must be changed by what we hear. The
                  purpose of preaching is not to make the preacher look good or
                  the listener feel good. The purpose is for all of us to be
                  more like Jesus because we have heard and submitted to the
                  Word.
                </p>
                <span className="block inline text-gray-500 font-bold italic">
                  Second, every sermon must be clearly and accurately drawn from
                  the Text of Scripture.
                </span>
                <p className="lg:ml-3 lg:mt-0">
                  The sermon must “read out the meaning” of the Text and never
                  “read in the desired meaning or opinions” of the preacher.
                  Even the most brilliant, interesting, and creative speaker
                  cannot match the wisdom and value of the Word of God. If a
                  preacher is only sharing his opinion or clever quotes or
                  compelling stories or scintillating “visions” he saw, he has
                  profoundly failed the listeners.
                </p>
                <span className="block text-gray-500 font-bold italic">
                  Third, the message must first impact the preacher before being
                  shared with the listeners.
                </span>
                <p className="lg:ml-3 lg:mt-0">
                  Preaching is not an “occupation” in the sense of being a way
                  to make money and gain status by speaking in public. Preaching
                  is a “vocation” in that I must hear from God through His Word,
                  then submit to His Truth, and then humbly share His Truth.
                </p>
                <span className="block text-gray-500 font-bold italic">
                  Fourth, in the Bible grace and truth are the valid methods of
                  motivation.
                </span>
                <p className="lg:ml-3 lg:mt-0">
                  I must say what is true even if it is hard to hear and I must
                  say it with grace. Guilt and shame are not valid or helpful
                  ways to motivate. Each person is generally stocked up for life
                  on both guilt and shame by the time they are ten. We get guilt
                  from those in authority over us, from the devil, from
                  religious systems, and most profoundly from ourselves. We need
                  to hear the hard things from the Bible to be sure. But we
                  don’t need a drubbing about being guilty sinners. Our primary
                  identity, once we have trusted Christ, is that of “saints.”
                  Our primary identity is not that of “sinners” and not even of
                  “sinners saved by grace.” We are saints who are capable of
                  sinning. As such, we need grace and truth, not guilt and
                  shame.
                </p>
                <span className="block text-gray-500 font-bold italic">
                  Fifth, quality preaching is hope based.
                </span>
                <p className="lg:ml-3 lg:mt-0">
                  It is the tenor of a presentation that says, “In dependence on
                  the Holy Spirit we can do this! God does not mock us by giving
                  us commands that we cannot obey. He does not entertain Himself
                  by watching us squirm and fail.” If He said to do it, we can
                  do it.
                </p>
                <span className="block text-gray-500 font-bold italic">
                  Sixth, helpful preaching is “we” based and not “you” based.
                </span>
                <p className="lg:ml-3 lg:mt-0">
                  The preacher who sees himself as a fellow learner from Jesus
                  is both more accurate in his thinking about reality and much
                  easier to hear. The preacher who preaches down from his
                  self-constructed pedestal of “maturity” or “calling” or
                  “training” or “fame” or “general arrogance” is nearly
                  impossible to learn from.
                </p>
              </div>
              <div className="mt-10">
                <Link
                  to="/sermons"
                  className="text-base font-medium text-cyan-600"
                >
                  Listen to all Dave's Sermons
                  <span aria-hidden="true"> &rarr;</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}
