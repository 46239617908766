import Navbar from "../components/Navbar"
import outside from "../images/daveOutside.jpg"

export default function About() {
  return (
    <div className="bg-white">
      <Navbar />

      <main className="relative overflow-hidden mb-16">
        <div className="mx-auto max-w-7xl mt-16 sm:mt-32">
          <div className="grid grid-cols-1 gap-y-16 lg:grid-cols-2 lg:grid-rows-[auto_1fr] lg:gap-y-12">
            <div className="lg:pl-20">
              <div className="max-w-xs px-4 lg:max-w-none">
                <img
                  src={outside}
                  alt=""
                  sizes="(min-width: 1024px) 32rem, 20rem"
                  className="aspect-square rotate-3 rounded-2xl bg-zinc-100 object-cover"
                />
              </div>
            </div>
            <div className="container mx-auto px-4 sm:px-6 lg:px-8 lg:order-first lg:row-span-2">
              <h1 className="text-4xl font-bold tracking-tight text-zinc-800 sm:text-5xl">
                <span className="block inline">About</span>{" "}
                <span className="block text-cyan-600 inline">Dave</span>{" "}
                <span className="block inline">Gibson</span>
              </h1>
              <div className="mt-6 space-y-7 text-base text-zinc-600">
                <p>
                  The most significant thing about me is that in the winter of
                  1971 Jesus tracked me down in my bedroom in Tucson, Arizona
                  and rescued me. I was in desperate need—a mental, emotional,
                  intellectual, and spiritual train wreck—when Jesus showed up.
                  That rescue has defined my entire life and my entire eternity.
                  Beyond this gracious rescue by God, I have a seventy-year
                  sojourn, but nothing that rivals or approaches that rescue.
                </p>
                <p>
                  I was born in Valentine, Nebraska because our home in He Dog,
                  South Dakota had only a school—no hospital, store, gas
                  station, or any commercial establishment. Beginning from He
                  Dog, South Dakota I have visited all 50 states, 43 countries,
                  and all the continents. I have been fry cook, a wheat
                  harvester, a freight dock worker, a professional forester, a
                  Bible college teacher, a pastor, and an international
                  missionary. Today I am retired in Eagle, Idaho with Kathi, my
                  wife of 48 years. We have three grown children and 10
                  grandchildren. For me retirement means investing my time in
                  family, friends, ministry, woodworking, camping, hunting,
                  travel, writing, and the daily pursuit of God. I have written
                  and released two books: Travel Required: 90 Days of Worry, and
                  In My Father’s Wake: How an Anxious Boy Learned to Love a
                  Difficult Dad. Both books are available on Amazon.
                </p>
                <p>
                  My training includes a BS in Forestry, a ThM in Bible, a DMin
                  in Preaching, and decades of “OJT” (on the job training). My
                  OJT major was in mistakes; and I minored in life,
                  relationships, do-overs, and ministry. Along the path I have
                  been helped, and continue to be helped by a census-load of
                  people: parents, wife, children, grandchildren, friends,
                  siblings, unbelievers, and fellow Christ-Followers too
                  numerous to count or even remember.
                </p>
                <p>
                  I am a big believer in “vocation” (hearing God’s voice for
                  calling and direction) as opposed to occupation, or work, or
                  job, or career. My vocation has included 3 years in forestry,
                  11 years in seminary, 8 years teaching Bible college, 22 years
                  as a senior pastor, and 8 years as a missions leader involved
                  in rapid church planting movements in 50 spiritually dark
                  countries.
                </p>
                <p>
                  God has been faithful to me and generous beyond
                  description—since eternity past and into eternity future! He
                  is good that way!
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}
