import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlay, faPause } from "@fortawesome/free-solid-svg-icons"
import ReactPlayer from "react-player/lazy"
import { useEffect, useRef, useState } from "react"
import { OnProgressProps } from "react-player/base"
import Duration from "./Duration"

export interface ISermonCard {
  title: string
  url: string
  activePlayer: string
  setActivePlayer: (sermonTitle: string) => void
}

export default function SermonCard(props: ISermonCard) {
  const { title, url, activePlayer, setActivePlayer } = props

  const [playing, setPlaying] = useState(false)
  const [played, setPlayed] = useState(0)
  const [seeking, setSeeking] = useState(false)
  const [duration, setDuration] = useState(0)

  const playerRef = useRef<ReactPlayer | null>(null)

  useEffect(() => {
    activePlayer !== title && setPlaying(false)
  }, [activePlayer, title])

  const handlePlayPause = () => {
    setActivePlayer(title)
    setPlaying(!playing)
  }

  const handleSeekMouseDown = (_e: any) => {
    setSeeking(true)
  }

  const handleSeekChange = (e: any) => {
    setPlayed(parseFloat(e.target.value))
  }

  const handleSeekMouseUp = (e: any) => {
    setSeeking(false)
    playerRef.current && playerRef.current.seekTo(parseFloat(e.target.value))
  }
  const handleProgress = (state: OnProgressProps) => {
    // We only want to update time slider if we are not currently seeking
    if (!seeking) {
      setPlayed(state.played)
    }
  }

  return (
    <div className="mt-6 flex flex-col">
      <ReactPlayer
        ref={playerRef}
        className="react-player"
        width="100%"
        height="0"
        url={url}
        playing={activePlayer === title && playing}
        onProgress={handleProgress}
        onDuration={(d) => setDuration(d)}
        onError={() => setPlaying(false)}
      />

      <div className="relative z-10">
        <div className="flex w-full rounded-lg bg-white shadow-xl shadow-black/5 ring-1 ring-slate-700/10">
          <div className="flex items-center space-x-4 py-4 px-6 w-1/4">
            <button
              type="button"
              onClick={handlePlayPause}
              className="inline-flex items-center rounded-full border border-transparent bg-cyan-600 p-2 text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
            >
              <FontAwesomeIcon
                icon={playing ? faPause : faPlay}
                className="h-5 w-5"
                aria-hidden="true"
              />
            </button>
          </div>
          <div className="flex-column items-center py-4 px-6 w-3/4">
            <p className="text-sm font-medium text-gray-900">
              <span className="text-gray-500">{title}</span>
            </p>
            <div>
              <div className="overflow-hidden">
                <input
                  className="h-2 rounded-full"
                  type="range"
                  min={0}
                  max={0.999999}
                  step="any"
                  value={played}
                  onMouseDown={handleSeekMouseDown}
                  onChange={handleSeekChange}
                  onMouseUp={handleSeekMouseUp}
                  onEnded={() => setPlayed(0)}
                />
              </div>
            </div>
            <div className="grid-cols-2 text-sm font-medium text-gray-600 sm:grid">
              <Duration seconds={duration * played} />
              <div className="text-right">
                <Duration seconds={duration} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
