import { useEffect, useState } from "react"
import { SermonData } from "../../global/dataTypes"
import SermonCard from "../SermonCard"
import axios from "axios"

export default function SideScrollComponent() {
  const [sermons, setSermons] = useState<SermonData[]>([])
  const [activePlayer, setActivePlayer] = useState("")

  useEffect(() => {
    axios
      .get("data.json", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((res) => {
        const allSermons = [...res.data.sermons].sort(
          () => Math.random() - 0.5
        ) as SermonData[]
        setSermons(allSermons.slice(0, 10))
      })
  }, [])

  return (
    <div className="relative rounded-xl overflow-x-auto flex gap-6">
      {sermons.map((sermon) => {
        return (
          <div
            key={sermon.embedMedium}
            className="snap-center shrink-0 first:pl-4 last:pr-4"
          >
            {process.env.REACT_APP_USE_CUSTOM_PLAYER === "true" ? (
              <SermonCard
                title={sermon.title}
                url={sermon.soundCloudUrl}
                activePlayer={activePlayer}
                setActivePlayer={setActivePlayer}
              />
            ) : (
              <div className="mt-6 flex flex-col">
                <iframe
                  title={sermon.title}
                  width="100%"
                  height="166"
                  allow="autoplay"
                  src={sermon.embedMedium}
                ></iframe>
              </div>
            )}
          </div>
        )
      })}
    </div>
  )
}
