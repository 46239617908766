import Navbar from "../components/Navbar"
import ScrollComponents from "../components/sermons/ScrollComponents"
import worship from "../images/worship.jpg"

export default function Sermons() {
  return (
    <div className="bg-white">
      <Navbar />
      <main className="relative mx-auto">
        <div className="mt-10 bg-white pb-12 lg:pb-20">
          <div className="relative z-0">
            <div className="absolute inset-0 h-1/2">
              <img
                className="h-full w-full object-cover"
                src={worship}
                alt="worship"
              />
              <div className="absolute inset-0 bg-indigo-700 mix-blend-multiply" />
            </div>
            <div className="flex relative justify-center">
              <h1 className="flex pt-10 text-4xl font-bold tracking-tight text-white sm:text-5xl gap-2">
                <span>All</span>
                <span className="text-cyan-600 ">Sermons</span>
              </h1>
            </div>
            <div className="flex px-2 py-8  justify-center">
              <ScrollComponents />
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}
