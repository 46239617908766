import { useEffect, useState } from "react"
import { SermonData } from "../../global/dataTypes"
import axios from "axios"
import Pagination from "../Pagination"
import { paginate } from "../../utils/paginate"
import SearchSermonsBar from "./SearchSermonsBar"

const PAGE_SIZE = 10

export default function ScrollComponents() {
  const [sermons, setSermons] = useState<SermonData[]>([])
  const [selectedSermon, setSelectedSermon] = useState<SermonData | null>(null)
  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    axios
      .get("data.json", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((res) => {
        const sortedSermons = res.data?.sermons.sort() ?? []
        setSermons(sortedSermons)
      })
  }, [])

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }
  const selectedSermons = sermons.filter(
    (sermon) => sermon.title === selectedSermon?.title
  )

  const paginatedSermons = paginate(
    selectedSermons.length > 0 ? selectedSermons : sermons,
    currentPage,
    PAGE_SIZE
  )

  console.log("ps", paginatedSermons)

  return (
    <div className="flex flex-col w-full lg:w-2/3">
      <div className="relative h-24 w-full">
        <div className="absolute p-10 pt-0 w-full md:w-1/2 h-24 inset-y-0 right-0">
          <SearchSermonsBar
            sermons={sermons}
            selectedSermon={selectedSermon}
            setSelectedSermon={setSelectedSermon}
          />
        </div>
      </div>
      <div
        className="overflow-y-auto h-96 w-full rounded-t-xl bg-slate-800"
        aria-label="Sermons"
      >
        <div className="sticky top-0 border-t border-b border-slate-800 bg-slate-800 px-6 py-1 text-sm font-medium text-gray-500"></div>
        {paginatedSermons.map((sermon) => (
          <div
            key={sermon.embedMedium}
            className="relative z-0 divide-y divide-gray-200"
          >
            <div className="bg-slate-800">
              {paginatedSermons.length === 1 ? (
                <div className={`flex flex-col p-12`}>
                  <iframe
                    title={sermon.title}
                    width="100%"
                    height="20"
                    src={sermon.embedMedium}
                  ></iframe>
                </div>
              ) : (
                <div className={`flex flex-col p-5`}>
                  <iframe
                    title={sermon.title}
                    width="100%"
                    height="20"
                    src={sermon.embedMedium}
                  ></iframe>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
      <div className="w-full">
        <Pagination
          itemsCount={
            selectedSermons.length > 0 ? selectedSermons.length : sermons.length
          }
          pageSize={PAGE_SIZE}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  )
}
